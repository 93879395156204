import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import myImg from "../../Assets/profile-picture.jpg";
// badge images
import awsqst from "../../Assets/cloud-badges/cloud-quest.png";
import awsess from "../../Assets/cloud-badges/cloud-essentials.png";
import awsops from "../../Assets/cloud-badges/cloud-ops.png";
import awscompute from "../../Assets/cloud-badges/cloud-compute.png";
import awsdb from "../../Assets/cloud-badges/cloud-db.png";
import awsintro from "../../Assets/cloud-badges/cloud-intro.png";
import awsnetworking from "../../Assets/cloud-badges/cloud-networking.png";
import awssecurity from "../../Assets/cloud-badges/cloud-security.png";
import awsstorage from "../../Assets/cloud-badges/cloud-storage.png";
import awscp from "../../Assets/cloud-badges/cloud-practitioner.png";
import awssls from "../../Assets/cloud-badges/cloud-serverless.png";
import awssol from "../../Assets/cloud-badges/solution-architect.png";
import awsquestsol from "../../Assets/cloud-badges/quest-solution-architect.png";
import awsquestdev from "../../Assets/cloud-badges/serverless-developer.png";
import awsdev from "../../Assets/cloud-badges/developer-associate.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  const certifications = [
    {
      title: "AWS Certified Developer – Associate",
      image: awsdev,
      link: "https://www.credly.com/badges/fd3a9d39-0e2e-4b08-9d9f-cff75dea069d/public_url"
    },
    {
      title: "AWS Certified Solutions Architect – Associate",
      image: awssol,
      link: "https://www.credly.com/badges/ad90700c-7390-4707-bb03-95050324f9d4/public_url"
    },
    {
      title: "AWS Certified Cloud Practitioner",
      image: awscp,
      link: "https://www.credly.com/badges/1f60e5db-f4b1-43bf-bdc9-c49118c2b670/public_url"
    },
    // {
    //   title: "AWS Cloud Quest: Solutions Architect",
    //   image: awsquestsol,
    //   link: "https://www.credly.com/badges/f81618cc-2d8e-45fa-b2d6-6bd076279956/public_url"
    // },
    // {
    //   title: "AWS Cloud Quest: Serverless Developer",
    //   image: awsquestdev,
    //   link: "https://www.credly.com/badges/aae1aa02-769f-473a-b607-2f29da692f1a/public_url"
    // },
    // {
    //   title: "AWS Cloud Quest: Cloud Practitioner",
    //   image: awsqst,
    //   link: "https://www.credly.com/badges/ae4a93c9-aa9a-420b-a286-ada076069228/public_url"
    // },
    // {
    //   title: "AWS Knowledge: Cloud Essentials",
    //   image: awsess,
    //   link: "https://www.credly.com/badges/b7862d8e-eddf-400f-8209-078d19ea1fb7/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Cloud Ops",
    //   image: awsops,
    //   link: "https://www.credly.com/badges/cb753842-1e84-4201-962b-abe770c45ab9/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Serverless",
    //   image: awssls,
    //   link: "https://www.credly.com/badges/467748b3-134c-42a7-a78f-294d03b5d26e/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Compute",
    //   image: awscompute,
    //   link: "https://www.credly.com/badges/313eebc0-b3d8-4fde-b5a9-4c3c71f10ce2/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Databases",
    //   image: awsdb,
    //   link: "https://www.credly.com/badges/e5f367be-fd1d-4938-a93f-3679c963454e/public_url"
    // },
    // {
    //   title: "AWS Educate Introduction to Cloud 101",
    //   image: awsintro,
    //   link: "https://www.credly.com/badges/0f053dbd-e67e-4f0d-a080-5f3e2812c045/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Networking",
    //   image: awsnetworking,
    //   link: "https://www.credly.com/badges/5f9d285d-3001-4f8e-af1d-3dcb07dae2c1/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Security",
    //   image: awssecurity,
    //   link: "https://www.credly.com/badges/c9f75189-5545-4868-8f1c-cb5d1c50a1ff/public_url"
    // },
    // {
    //   title: "AWS Educate Getting Started with Storage",
    //   image: awsstorage,
    //   link: "https://www.credly.com/badges/b2a4cb03-28cc-42e1-a3ea-894b50e50b59/public_url"
    // },
  ];

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        {/* Existing intro section */}
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Hey, <span className="purple"> I'm Gedion </span> 👋🏻
            </h1>
            <p className="home-about-body">
              I'm a problem solver who enjoys learning and sharing knowledge.
              <br />
              <br />
              My fields of interest include building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> as well as cloud computing with {" "}
                <b className="purple">
                  AWS.
                </b>
              </i>
              <br />
              <br />
              I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                className="img-fluid"
                alt="avatar"
                style={{
                  borderRadius: '50%',
                  width: '240px',
                  height: '240px',
                  objectFit: 'cover',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
                }}
              />
            </Tilt>
          </Col>
        </Row>

        {/* Certifications Section */}
<Row className="certification-section">
  <Col md={12}>
    <h1 style={{ fontSize: "2.6em", textAlign: "center", marginBottom: "0.5em" }}>
      <span className="purple">Certifications</span>
    </h1>
  </Col>
  <Row style={{ justifyContent: "center", gap: "10px" }}>
    {certifications.map((cert, index) => (
      <Col xs={6} md={3} lg={2} className="certification-card" key={index}>
        <a href={cert.link} target="_blank" rel="noreferrer" className="certification-link">
          <img
            src={cert.image}
            alt={cert.title}
            className="certification-image"
            title={cert.title}
          />
        </a>
      </Col>
    ))}
  </Row>
</Row>

        {/* Social Links Section */}
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Gediontkd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/gedion-daniel-760ba6280/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gediondev/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;