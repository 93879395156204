import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Import all project images
import editor from "../../Assets/Projects/survey.png";
import chatify from "../../Assets/Projects/evangadiforum.jpg";
import bitsOfCode from "../../Assets/Projects/yared-garage.jpg";
import awsproject1 from "../../Assets/aws-cloud-projects/project-1-architecture-diagram.jpeg";
import awsproject2 from "../../Assets/aws-cloud-projects/project-2-architecture-diagram.jpeg";
import awsproject3 from "../../Assets/aws-cloud-projects/project-3-architecture-diagram.jpeg";
import awsproject4 from "../../Assets/aws-cloud-projects/project-4-architecture-diagram.jpeg";
import awsproject5 from "../../Assets/aws-cloud-projects/project-5-architecture-diagram.jpeg";
import awsproject6 from "../../Assets/aws-cloud-projects/project-6-architecture-diagram.jpeg";
import awsproject7 from "../../Assets/aws-cloud-projects/project-7-architecture-diagram.jpeg";
import awsproject8 from "../../Assets/aws-cloud-projects/project-8-architecture-diagram.jpeg";
import awsproject9 from "../../Assets/aws-cloud-projects/project-9-architecture-diagram.jpeg";
import awsproject10 from "../../Assets/aws-cloud-projects/project-10-architecture-diagram.jpeg";
import awsproject11 from "../../Assets/aws-cloud-projects/project-11-architecture-diagram.jpeg";
import awsproject12 from "../../Assets/aws-cloud-projects/project-12-architecture-diagram.jpeg";
import awsproject13 from "../../Assets/aws-cloud-projects/project-13-architecture-diagram.jpeg";
import awsproject14 from "../../Assets/aws-cloud-projects/project-14-architecture-diagram.jpeg";

function Projects() {
  const [activeCategory, setActiveCategory] = useState("cloud");

  // Cloud Projects Data
  const cloudProjects = [
    {
      imgPath: chatify,
      title: "Evangadi Forum",
      description: "Built with React.js and Express, our platform serves as a hub for Evangadi suggestions and student queries. Students can ask questions and offer suggestions, fostering community engagement and support",
      technologies: ["React.js", "Express", "Node.js", "MongoDB"],
      ghLink: "https://github.com/Gediontkd/Evangadi-Forum",
      demoLink: "https://evanagadi-forum.netlify.app"
    },
    {
      imgPath: bitsOfCode,
      title: "Yared Garage",
      description: "This project is a comprehensive software solution meticulously crafted to streamline and elevate the day-to-day workflow of Yared Garage. The application is designed to simplify key processes such as customer intake, appointment management, and task tracking, with the ultimate goal of enhancing operational efficiency and maximizing customer satisfaction.",
      technologies: ["React.js", "Node.js", "Express", "MongoDB"],
      ghLink: "https://github.com/Gediontkd/Yared-Grarage-Main-App",
      demoLink: "https://yaredgarage.com/"
    },
    {
      imgPath: editor,
      title: "laravel-vue-survey",
      description: "This project is a full-stack web application designed to streamline the process of creating and managing surveys. This project integrates Vue 3 on the frontend with Tailwind CSS 3 for styling, while utilizing Laravel 8 on the backend to handle data management and server-side logic.",
      technologies: ["Vue.js", "Laravel", "Tailwind CSS", "MySQL"],
      ghLink: "https://github.com/Gediontkd/laravel-vue-survey",
      demoLink: "https://drive.google.com/file/d/1nDzpTveKZTup4GCUOg_u0Kz00ogiWof8/view"
    },
    {
      imgPath: awsproject1,
      title: "Highly Available Web Application on AWS",
      description: "Architected highly available web application infrastructure on AWS, implementing auto-scaling groups, load balancers, and multi-AZ deployment for maximum reliability.",
      technologies: ["AWS", "EC2", "RDS", "Route53", "ELB", "S3", "CloudFront", "CloudWatch"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Highly-Available-Web-Application"
    },
    {
      imgPath: awsproject2,
      title: "API with Database",
      description: "Created a RESTful API using Amazon API Gateway, AWS Lambda, and DynamoDB.",
      technologies: ["AWS", "Lambda", "API Gateway", "DynamoDB"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/API-with-Database"
    },
    {
      imgPath: awsproject3,
      title: "Analyze Network Traffic",
      description: "Monitored and secured network traffic by detecting and blocking unauthorized IPs using AWS services.",
      technologies: ["AWS", "EC2", "VPC", "NACLs"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Analyzing-Network-Traffic"
    },
    {
      imgPath: awsproject4,
      title: "Automation with CloudFormation",
      description: "Built an automated AWS infrastructure deployment using AWS CloudFormation.",
      technologies: ["AWS", "CloudFormation", "EC2", "S3", "SG", "IAM"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Automation-with-CloudFormation"
    },
    {
      imgPath: awsproject5,
      title: "Backing up Data",
      description: "Automated backups using EBS snapshots and Aurora Serverless.",
      technologies: ["AWS", "Aurora", "Backup", "KMS", "EBS", "EC2"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Backing-up-Data"
    },
    {
      imgPath: awsproject6,
      title: "Container Services",
      description: "Built and deployed containerized applications on AWS using ECR, ECS, and Fargate, ensuring scalable and efficient deployment.",
      technologies: ["AWS", "ECR", "ECS", "Farget", "EC2"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Container-Services"
    },
    {
      imgPath: awsproject7,
      title: "Content Delivery Networks",
      description: "Designed and configured a CDN architecture using CloudFront.",
      technologies: ["AWS", "CloudFront", "EC2", "S3", "SG", "CDN"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Content-Delivery-Networks"
    },
    {
      imgPath: awsproject8,
      title: "DNS",
      description: "Configured a private hosted zone in Route 53 for VPC domain names, managing A and CNAME records for local routing.",
      technologies: ["AWS", "Route53", "EC2", "VPC", "DNS"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/DNS"
    },
    {
      imgPath: awsproject9,
      title: "Data Ingestion",
      description: "Implemented a clickstream data pipeline using Kinesis Data Firehose, S3, and Lambda to ingest, store, and analyze real-time page views.",
      technologies: ["AWS", "Kinesis", "Lambda", "S3", "DynamoDB"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Data-Ingestion-Methods"
    },
    {
      imgPath: awsproject10,
      title: "Decoupling Application",
      description: "Decoupled application using Amazon Simple Queue Service (SQS) and Amazon Simple Notification Service (SNS).",
      technologies: ["AWS", "SQS", "SNS", "SNS Topic"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Decoupling-Applications"
    },
    {
      imgPath: awsproject11,
      title: "Deploying RESTful APIs",
      description: "Deployed a RESTful API using AWS Lambda and API Gateway, enabling serverless request handling and seamless integration.",
      technologies: ["AWS", "Lambda", "API Gateway"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Deploying-RESTful-APIs"
    },
    {
      imgPath: awsproject12,
      title: "Resources Governance",
      description: "Configured AWS Config managed rules for S3 KMS encryption, tagged resources, and S3 bucket versioning to ensure compliance and security.",
      technologies: ["AWS", "AWS Cloud Config", "EC2", "S3"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Resource-Governance"
    },
    {
      imgPath: awsproject13,
      title: "Resources Monitoring with Amazon CloudWatch",
      description: "Monitored AWS resources with CloudWatch metrics, set up alarms, actions, and created a CloudWatch dashboard for effective resource management.",
      technologies: ["AWS", "CloudWatch", "EC2"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Resource-Monitoring"
    },
    {
      imgPath: awsproject14,
      title: "AWS Serverless",
      description: "Introduced to serverless computing and AWS Lambda by developing a simple Lambda function.",
      technologies: ["AWS", "Amazon Lambda"],
      ghLink: "https://github.com/Gediontkd/AWS-Cloud-Quest-Projects/tree/main/Servless-Foundation"
    }
  ];

  // Full Stack Projects Data
  const fullStackProjects = [
    {
      imgPath: chatify,
      title: "Evangadi Forum",
      description: "Built with React.js and Express, our platform serves as a hub for Evangadi suggestions and student queries. Students can ask questions and offer suggestions, fostering community engagement and support",
      technologies: ["React.js", "Express", "Node.js", "MongoDB"],
      ghLink: "https://github.com/Gediontkd/Evangadi-Forum",
      demoLink: "https://evanagadi-forum.netlify.app"
    },
    {
      imgPath: bitsOfCode,
      title: "Yared Garage",
      description: "This project is a comprehensive software solution meticulously crafted to streamline and elevate the day-to-day workflow of Yared Garage. The application is designed to simplify key processes such as customer intake, appointment management, and task tracking, with the ultimate goal of enhancing operational efficiency and maximizing customer satisfaction.",
      technologies: ["React.js", "Node.js", "Express", "MongoDB"],
      ghLink: "https://github.com/Gediontkd/Yared-Grarage-Main-App",
      demoLink: "https://yaredgarage.com/"
    },
    {
      imgPath: editor,
      title: "laravel-vue-survey",
      description: "This project is a full-stack web application designed to streamline the process of creating and managing surveys. This project integrates Vue 3 on the frontend with Tailwind CSS 3 for styling, while utilizing Laravel 8 on the backend to handle data management and server-side logic.",
      technologies: ["Vue.js", "Laravel", "Tailwind CSS", "MySQL"],
      ghLink: "https://github.com/Gediontkd/laravel-vue-survey",
      demoLink: "https://drive.google.com/file/d/1nDzpTveKZTup4GCUOg_u0Kz00ogiWof8/view"
    }
  ];

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>

        {/* Navigation Buttons
        <div className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => setActiveCategory("cloud")}
            className="mx-2 px-4 py-2"
            style={{
              backgroundColor: activeCategory === "cloud" ? "#623686" : "transparent",
              borderColor: "#623686",
              color: activeCategory === "cloud" ? "white" : "#623686"
            }}
          >
            Cloud Projects
          </Button>
          <Button
            onClick={() => setActiveCategory("fullstack")}
            className="mx-2 px-4 py-2"
            style={{
              backgroundColor: activeCategory === "fullstack" ? "#623686" : "transparent",
              borderColor: "#623686",
              color: activeCategory === "fullstack" ? "white" : "#623686"
            }}
          >
            Full Stack Projects
          </Button>
        </div> */}

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {activeCategory === "cloud" && 
            cloudProjects.map((project, index) => (
              <Col md={6} className="project-card" key={index}>
                <ProjectCard
                  imgPath={project.imgPath}
                  isBlog={false}
                  title={project.title}
                  description={project.description}
                  technologies={project.technologies}
                  ghLink={project.ghLink}
                  demoLink={project.demoLink}
                />
              </Col>
            ))
          }

          {activeCategory === "fullstack" && 
            fullStackProjects.map((project, index) => (
              <Col md={6} className="project-card" key={index}>
                <ProjectCard
                  imgPath={project.imgPath}
                  isBlog={false}
                  title={project.title}
                  description={project.description}
                  technologies={project.technologies}
                  ghLink={project.ghLink}
                  demoLink={project.demoLink}
                />
              </Col>
            ))
          }
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;